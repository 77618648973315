<template>
  <div class="examinerManagement">
    <div class="flex-box">
      <div class="left">
        
      </div>
      <div class="flex-box">
        <!-- <el-input
          placeholder="上传人"
          class="picker"
          v-model="filter.teacherid"
        ></el-input> -->
        <!-- 学年？ -->
      </div>
    </div>
    <el-table :data="PublishInformationTable">
      <el-table-column
        label="角色"
        prop="mname"
        align="center"
      ></el-table-column>
      <el-table-column
        label="状态"
        prop="status"
        align="center">
        <template slot-scope="scope">
            <el-tag :type="scope.row.status === 0 ? 'danger' : 'success'">{{scope.row.status === 0 ? '未读' : '已读'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="时间"
        prop="opertime"
        align="center"
      ></el-table-column>
    </el-table>

    <el-pagination
      layout="prev,pager,next"
      background
      :page-count="page.pageCount"
      :page-size="page.currentPage"
      @current-change="loadPublishInformation"
    ></el-pagination>

  </div>
</template>

<script>


export default {
  
  name: "TemplateReadCondition",
  data() {
    return {
      page: { pageCount: 1, currentPage: 0 },
      readid:this.$route.query.readid,
      PublishInformationTable: [],
        // 已读列表
        readList:[],
        // 未读列表
        noReadList:[],
      
      

    };
  },
  created() {
    this.loadPublishInformation(1);
    
  },
  methods: {
    // 获取初始页面数据
    loadPublishInformation(page) {
        let that = this
      this.$post(`/base/queryInformationPeopleList.do?id=${this.readid}`)
        .then((res) => {
            console.log(res)
          that.page.currentPage = res.data.indexNO;
          that.page.pageCount = res.data.pageCount;
            res.data.forEach(item =>{
                if(item.status === 0){
                    this.noReadList.push(item)
                }else if(item.status === 1){
                    this.readList.push(item)
                }
            })
          that.PublishInformationTable = [...this.readList,...this.noReadList];
          console.log(that.PublishInformationTable);
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    
  },
};
</script>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}
</style>
